body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}


:root {
  --primary-color: #0D94A6;
}

@font-face {
  font-family: 'Teko';
  src: local('Teko'), url(./fonts/Teko-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./fonts/Oswald-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'OswaldLight';
  src: local('Oswald'), url(./fonts/Oswald-ExtraLight.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Teko', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
}

.layout {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  height: 100%;
}

.ant-menu-horizontal {
  border-bottom: 0;
}

.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%
}

.ant-input-status-error:not(.ant-input-disabled){
  border-color: var(--primary-color) !important;
}

.ant-form-item-explain-error {
  color: var(--primary-color) !important;
}

.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  color: var(--primary-color) !important;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper{
  border-color: var(--primary-color) !important;
}

.ant-message-custom-content {
  display: inline-flex;
  align-items: center;
}

.ant-upload.ant-upload-select {
  width: 100% !important;
  height: 100% !important;
  aspect-ratio : 1 / 1;
}

.homeContent {
  background-image: url('/public/policecar.jpeg');
}

.mainPageImage {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.mainPageImage::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.6);
}

.valueProps {
  margin-top: 40px;
  margin-bottom: 40px;
}

.ant-layout-header {
  padding: 0;
}

.navbar-scrolled {
  background-color: #1e1e1e !important;
}

.buttonCard:hover {
  background-color: var(--primary-color);
}

.buttonCard:hover .ant-card-meta-description {
  color: rgba(255, 255, 255, 0.75);
}

.homeContentEven {
  background-color: #1e1e1e;
}

.homeContentOdd {
  background-color: #262626;
}

.ant-form-item .ant-form-item-control-input {
  height:100%;
}

.ant-form-item .ant-form-item-control-input-content {
  height:100%;
}

.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
  display: flex;
  justify-content: center;
}

section {
  opacity: 0;
  transform: translate(0%, 15%);
  visibility: hidden;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  will-change: opacity, visibility;
}

.header-underline {
  border-top: solid 1px var(--primary-color);
  width:170px;
  margin:auto;
  padding-top:40px;
}

.justify-left {
  margin-left: 0;
  margin-right: auto;
}

.value-highlight {
  color: var(--primary-color);
  font-weight: bold;
}

.ant-menu-inline  {
  border-inline-end: none !important;
}

@media (max-width:1225px){
  .headerMenu {
    display: none !important;
  }
}

.menuIcon {
  margin-left: auto;
}

@media (min-width:1225px){
  .menuIcon {
    display:none !important;
  }
}

.text-link {
  color: white;
}

.ant-btn:not(.ant-btn-link):not(.ant-btn-primary):hover,
.text-link:hover {
  color:  var(--primary-color) !important;
}

.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: rgba(42, 231, 255, 0.96);
}

.primary-color {
  color: var(--primary-color);
  font-size:28px;
}

.ant-form-vertical .ant-form-item-row {
  flex:1;
}

.flex-text-input.ant-form-item .ant-form-item-control-input-content {
  display: flex;
}

.app-details-card {
  border-color: var(--primary-color);
}

.main-page-title {
  text-align: center;
  margin-bottom: 32px;
}

.main-page-subtitle {
  text-align: center;
  font-family: "OswaldLight";
  font-size: 24px;
  margin-bottom: 32px;
}

.home-content-title {
  text-align:center;
}

.home-content-subtitle {
  font-family: "OswaldLight";
  font-size: 22px;
  margin-bottom: 84px;
  text-align: center;
}

.about-icon {
  font-size: 60px;
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-height: 600px) and (orientation:landscape) {
  .homeContent {
    height: 100%
  }

}

.registration-button {
  margin-left:auto;
  display: flex;
}

.ant-card .ant-card-actions>li {
  color: rgba(255, 255, 255, 0.7);
}

.open-in-btn {
  align-self: center;
  margin-left: 2px;
}

@media (max-width: 650px) {
  .open-in-btn {
    visibility: hidden;
    width: 0;
  }
}


@media (max-width: 750px) {

  .mainPageImage {
    background-position: 40% 20%;
  }

  div, span {
    font-size: 16px !important;
  }

  h1.ant-typography {
    font-size: 24px !important;
  }

  h2.ant-typography {
    font-size: 22px !important;
  }

  h3.ant-typography {
    font-size: 20px !important;
  }

  h4.ant-typography {
    font-size: 18px !important;
  }

  h5.ant-typography {
    font-size: 16px !important;
  }

  h6.ant-typography {
    font-size: 14px !important;
  }

  .about-icon {
    font-size: 32px !important;
  }

  .main-page-title {
    margin-top: 32px;
    text-align: center;
    font-size: 12px;
  }

  .main-page-subtitle {
    text-align: center;
    font-family: "OswaldLight";
    font-size: 16px !important;
    margin-bottom: 32px;
  }

  .home-content-title {

  }

  .home-content-subtitle {
    margin-bottom: 32px;
  }

  .header-underline {
    width: 145px;
    padding-top: 24px;
  }

  .about-title {
    margin-top: 0;
  }

  .gap-column {
    padding: 0 !important;
  }

  .ant-result-icon span {
    font-size: 32px !important;
  }

  .registration-button {
    padding: 4px;
  }
}